<template>
  <!-- prettier-ignore -->
  <form-input
    v-model="date"
    v-bind="$attrs"
    inputmode="decimal"
    manual-validation
    type="text"
    :delay="$props.delay"
    :hint="$props.hint"
    :is-active="$props.isActive"
    :mask="'##.##.####'"
    :theme="$props.theme"
    :title-label="$props.titleLabel"
    :value="date"
    :validate="$props.validate"
  >
    <slot />
  </form-input>
</template>

<script setup>
import { ref, unref, watch, watchEffect } from 'vue'

import { DateTimeHelper } from '@/helpers'

import FormInput from '@/components/Form/TextInput'

import { DATE_FORMAT_DIN, DATE_FORMAT_ISO } from '@/config/constants'

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  delay: {
    type: Number,
    default: 0,
  },
  hint: {
    type: [String],
    default: '',
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  titleLabel: {
    type: String,
    required: true,
  },
  validate: {
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: [String, Number],
    default: undefined,
  },
})

// DATA
let date = ref(null)

// WATCHERS
watchEffect(() => {
  if (props.modelValue) {
    const _date = unref(props.modelValue)

    date.value = DateTimeHelper.formatDate(_date, DATE_FORMAT_DIN)

    // if manually emptied
  } else if (props.modelValue === null) {
    date.value = null
  }
})

watch(date, value => {
  if (value?.length === 10) {
    props.validate?.$touch()

    try {
      emit('update:modelValue', DateTimeHelper.formatDate(value, DATE_FORMAT_ISO))
    } catch {
      /* empty */
    }
  }
})
</script>
